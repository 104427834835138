import React from 'react'
import Select from 'react-select';

export default function Form(props) {
  return (
    <div>
      <div className="col-md-6 grid-margin stretch-card">
              <div className="card">
                <div className="card-body">
                <form className="forms-sample" onSubmit={props.submit} id="form">
                {props.form.map(data => (
                    <div>
                        {data.type === "input" ? <div className="form-group">
                      <label for="exampleInputName1">{data.label}</label>
                      <input className="form-control" onChange={data.change} name={data.name}  placeholder={data.ph} type="text"/>
                    </div> : <div className="form-group"><label for="exampleInputName1">{data.label}</label><Select onChange={data.change} placeholder={data.ph} options={data.opts}/></div>}
                    </div>
                ))}
                  
                    
                    <button type="submit" className="btn btn-success mr-2"> <span className={"fa fa-"+props.loader}></span> {props.btnTxt} </button>
                   
                  </form>
                </div>
              </div>
            </div>
    </div>
  )
}
