import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import Form from '../../../components/Form';
import {Api} from '../../../helper/Api';
import { ToastStore, ToastContainer } from 'react-toasts';
class Edit extends Component {
  constructor()
  {
    super()
    this.state = {
      loader: "check",
      btnTxt: "SAVE",
      departments: [],
      stockId: "",
      stockName: "",
      returnStatus: "",
      description: "",
      departmentId: "",
      updateColumn: "",
      updateValue: "s",
      quantity: "",
      quantity: [],
      stockData: []
    }
    this.getStockInfo = this.getStockInfo.bind(this)
    this.handleType = this.handleType.bind(this)
    this.onchange = this.onchange.bind(this)
    this.onsubmit = this.onsubmit.bind(this)
  }
  componentDidMount()
  {
    this.getStockInfo()
  }
  onchange(e)
  {
    this.setState({updateValue: e.target.value})
    
  }
  getStockInfo()
  {
    const {clientId} = this.props.match.params;
    const postData = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"), stockId:clientId})
    }
    Api("/stock/manage/find", postData).then(data => {this.setState({stockData: data})});
    Api("/stock/manage/quantity", postData).then(data => { this.setState({ quantity: data }) });
  }
  handleType(selectedOption)
  {
    this.setState({updateColumn: selectedOption.value})
  }
  onsubmit(e)
  {
    e.preventDefault()
    this.setState({loader: "spinner fa-spin", btnTxt:"SAVING.."});
    const body = {
      token: "Bearer "+localStorage.getItem("appToken"), updateColumn: this.state.updateColumn, updateValue: this.state.updateValue, updateKey: this.props.match.params.clientId
    }
    const data =  {method: 'PoST', body:JSON.stringify(body)}
    Api("/stock/manage/edit", data).then(data => {ToastStore.success(data.message); this.setState({loader: "check", btnTxt:"SAVE"})})
  }
  render(){

    const updateOptions = [ { value: "STOCK_NAME", label: "STOCK_NAME" }, { value: "UNITS", label: "UNITS" }, { value: "PRICE", label: "PRICE" }, {value: "RETURN_STATUS", label:"RETURN STATUS"}]
  return (
    <div>
      <ToastContainer store={ToastStore} />
      <div className="breadcrumb">
      <Link className="btn   btn-warning" to="/stock"> <span className="fa fa-arrow-left"></span> Back</Link>
      </div>
      <h3>EDITING STOCK ID:  {this.props.match.params.clientId}</h3>
      <div className="card">
           <div className="row">
           <div className="col-md-4">
           {this.state.stockData.map(info => (<div className="text-center">
           <h4>STOCK INFO</h4>
           <table className="table table-bordered table-stripped">
           <thead>
             <tr>
               <th>INFO</th>
               <th>DETAILS</th>
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>STOCK NAME</td>
               <td>{info.STOCK_NAME}</td>
             </tr>
                 <tr>
                   <td>CREATION DATE</td>
                   <td>{info.DATE_OF_CREATETION}</td>
                 </tr>
                 <tr>
                   <td>SAVE TYPE</td>
                   <td>{info.STOCK_SAVE_TYPE.toUpperCase()}</td>
                 </tr>
                 <tr>
                   <td>QUANTITY</td>
                   <td>{this.state.quantity.quantity}</td>
                 </tr>
                 <tr>
                   <td>RETURN STATUS</td>
                   <td>{info.RETURN_STATUS}</td>
                 </tr>

           </tbody>
           </table>

           </div>))}
           </div>
           <div className="col-md-8">
           <Form btnTxt={this.state.btnTxt} loader={this.state.loader} submit={this.onsubmit} form={[{type: "select", label:"SAVE UPDATE FIELD",opts:updateOptions, ph: "UPDATE FIELD", change:this.handleType},{type: "input", name:"description", label:"UPDATE VALUE", ph: "ENTER NEW INFORMATION", change:this.onchange}]}/>
           </div>
           </div>
      </div>
    </div>
  )

}
}

export default Edit;