import React, {Component} from 'react'
import Tables from '../../../components/Tables';

import { Link, Redirect } from 'react-router-dom';
import { Api } from '../../../helper/Api';


class View extends Component {
  constructor()
  {
    super()
    this.state = {
      stockData: []
    }
    this.getStocks = this.getStocks.bind(this);
  }

  getStocks(){
    Api("/department/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({stockData: data});
    })
  }
  componentDidMount = () => {
    this.getStocks();
  }
  deleteRow(rowKeys)
  {
    return(<Redirect to="/client/edit" />)
    
  }
  render(){
  return (
    <div>
     <div className="breadcrumb">
     <Link to="/depts/new"  className="btn   btn-primary" > <span className="fa fa-plus-circle"></span>NEW DEPARTMENT</Link> 

     </div>
     <h3>View Departments</h3>
       <Tables search="DEPARTMENT"  uri='depts' columns={[{column:"DEPARTMENT ID",dataField:"DEPARTMENT_ID"}, {column:"DEPARTMENT NAME",dataField:"DEPARTMENT"}]} data={this.state.stockData} keyId="DEPARTMENT_ID"/>
    </div>
  )
  }
}

export default View;
