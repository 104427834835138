import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import logo from "../../imgs/Final BLACK.jpg"
import { Api, api } from '../../helper/Api';
import {ToastContainer, ToastStore} from 'react-toasts'
export default class Workspace extends Component {
  constructor()
  {
    super()
    this.state = {
      logo: "",
      Workspace: "",
      location: "",
      contacts: "",
      otherInfo: "",
      imgData: "",
      filestat: false,
      orgId: ""
    }
    this.fileHandle = this.fileHandle.bind(this);
    this.onchange = this.onchange.bind(this)
    this.submit = this.submit.bind(this)
    this.getInfo = this.getInfo.bind(this)
  }
  componentWillMount()
  {
    this.getInfo()
  }

  fileHandle(e)
  {
    var fileDriver= new FileReader()
    var file = e.target.files[0];
    if(file.type.split("/")[0] === 'image')
    {
      fileDriver.readAsDataURL(file)
      fileDriver.onload = (data) => {
        var imgData = data.target.result
        this.setState({ logo: this.state.orgId+'.png', imgData: imgData, filestat: true });
        var prev = document.querySelector('#logo')
        prev.src = imgData
      }
    } else {
      this.setState({
        filestat: false
      })
    }
  }
  submit(e)
  {
    e.preventDefault()
    var data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"),
      fileData: this.state.imgData,
      FILE_CHANGE: this.state.filestat,
      logo: this.state.logo,
      workspace: this.state.Workspace,
      location: this.state.location,
      contacts: this.state.contacts,
      otherInfo: this.state.otherInfo
    })
    }

    //save it

    Api("/workspace/manage/edit", data).then(data => {ToastStore.success(data.message)})
  }

  onchange(e)
  {
    this.setState({[e.target.name]: e.target.value});
  }
  getInfo()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
    }
    Api("/workspace/manage/find", data).then(data =>{
      var datas = data[0]
      console.log(datas);
      
      this.setState({ Workspace: datas.ORGANIZATION_NAME, location: datas.LOCATION, contacts: datas.CONTACT, otherInfo: datas.OTHER_INFO, orgId: datas.ORGANIZATION_ID, logo:datas.LOGO})
    })
  }
  render() {
    console.log(this.state);
    
    return (
<div className="main-panel">
        <div className="content-wrapper">
        
        <ToastContainer store={ToastStore} />
        <h1><span className="fa fa-wrench"></span> WORKSPACE SETTINGS AND CONFIGS</h1>

        <hr/>
        <div className="card">
        
              <div className="card-header">BASIC INFORMATION</div>
                <div className="card-body">
                <div className="card-body"><img src={api+"/uploads/"+this.state.logo} className="avatar" id="logo" /><br/></div>
                <form action="" onSubmit={this.submit}>
                <div className="form-group col-md-6">
                <label htmlFor="">CHOOSE LOGO</label>
                <input type="file"  accept="image/*" className="form-control" onChange={this.fileHandle} name="" id=""/>
                </div>
                <div className="form-group col-md-6">
                <label htmlFor="">ORGANIZATION / WORKSPACE NAME</label>
                <input value={this.state.Workspace} type="text" className="form-control" name="Workspace" onChange={this.onchange} id=""/>
                </div>
                <div className="form-group col-md-6">
                <label htmlFor="">LOCATION</label>
                <input value={this.state.location} type="text" className="form-control" name="location" onChange={this.onchange} id=""/>
                </div>
                <div className="form-group col-md-6">
                <label htmlFor="">CONTACT</label>
                <input type="text" value={this.state.contacts} className="form-control" name="contacts" onChange={this.onchange} id=""/>
                </div>
                <div className="form-group col-md-6">
                <label htmlFor="">OTHER NOTES</label>
                <textarea type="textarea" value={this.state.otherInfo} className="form-control" onChange={this.onchange} name="otherInfo" id=""/>
                </div>
                <div className="form-group">
                <button className="btn btn-primary">SAVE</button>
                </div>
                </form>
                </div>
        </div>
        </div>
        </div>
        
    )
  }
}
