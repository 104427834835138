import React, {Component} from 'react'
import Tables from '../../../components/Tables';

import { Link, Redirect } from 'react-router-dom';
import { Api } from '../../../helper/Api';
import Select from 'react-select'
class Outgoing extends Component {
  constructor()
  {
    super()
    this.state = {
      clients: [],
      pending: [],
      amount: "",
      message: "",
      alert: ""
    }
    this.getClients = this.getClients.bind(this);
    this.fetchPending = this.fetchPending.bind(this)
  }

  getClients()
  {
    var data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
    }
    Api("/clients/manage/list", data).then(data => {this.setState({clients: data})});
  }
  componentWillMount()
  {
    this.getClients()
  }

  fetchPending(selected)
  {
    var postData = {
      method: 'POST',
      body: JSON.stringify({
        token: "Bearer "+localStorage.getItem("appToken"),
        clientId: selected.value
      })
    }

    Api('/stock/exchange/pending', postData).then(data => {
      this.setState({
        pending: data
      })
    })
  }
  render()
  {
    const clients = this.state.clients.map(data => ({value: data.CLIENT_ID, label:data.CLIENT_NAME}))
    return(
     <div>
        <div className="breadcrumb">
      <Link to="/exchange" className="btn btn-primary"> <span className="fa fa-upload"></span> GIVE OUT STOCK </Link>
    </div>
    <div className="card">
    <div className="card-body">
    <div className={this.state.alert}>
    <strong>{this.state.message}</strong></div>
      <form action="" className="col-md-6">
      <div className="form-group">
      <label htmlFor="">SELECT CLIENT TO VIEW PENDING RETURNS</label>
      <Select options={clients} placeholder="SELECT CLIENT " onChange={this.fetchPending} />
      </div>
      </form>

      
    </div>
    
    <div className="row">
    <div className="col-md-6">
      <b>Grouped</b>
      <table className="table table-bordered table-hover table-stripped">
        <tr>
          <th>Stock Id</th>
          <th>Status</th>
          <th>Quantity</th>
          <th>Action</th>
        </tr>
        {
                  this.state.pending.length < 1 ? <tr> <td>Client Has No Pending returns</td></tr> : this.state.pending.grouped.map(data => (<tr>
                    <td>
                      {data.STOCK_ID}
                    </td>
                    <td>
                      {data.STATUS}
                    </td>
                    <td contentEditable onBlur={(e)=>{
                      this.setState({
                        amount: e.currentTarget.textContent
                      })
                    }}>
                      {data.AMOUNT}
                    </td>
                    <td>
                      <button className="btn btn-x btn-info" name={data.STOCK_ID} id={data.PRODUCT_ID} onClick={(e) => {
                        const productId = e.currentTarget.id
                        const data = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: "Bearer " + localStorage.getItem("appToken"),
                            amount: this.state.amount,
                            stockId: e.currentTarget.name,
                            mode: 'grouped'
                          })
                        }
                       const ati= this.state.amount === "" ? ()=>{this.setState({alert: "alert alert-warning", message: "Please Enter Amount For Return"})} : ()=>{
                          Api('/stock/exchange/return', data).then(data => {
                            this.setState({
                              alert: "alert alert-info",
                              message: data.message
                            })
                            //this.fetchPending()

                          })
                        }

                        ati()
                      }
                      }>Return</button>
                    </td>
                  </tr>))
        }
      </table>
    </div>
    <div className="col-md-6">
      <b>Individual</b>
              <table className="table table-bordered table-hover table-stripped">
                <tr>
                 
                  <th>Product</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {
                  this.state.pending.length < 1 ? <tr> <td>Client Has No Pending returns</td></tr> : this.state.pending.individual.map(data => (<tr>
                    <td>
                      {data.PRODUCT_ID}
                    </td>
                    <td>
                      {data.STATUS}
                    </td>
                    <td>
                      <button className="btn btn-x btn-info" name={data.STOCK_ID} id={data.PRODUCT_ID} onClick={(e)=>{
                        const productId = e.currentTarget.id
                        const data = {
                          method: 'POST',
                          body: JSON.stringify({
                            token: "Bearer "+localStorage.getItem("appToken"),
                            amount: productId,
                            stockId: e.currentTarget.name,
                            mode: 'Individual'
                          })
                        }
                        Api('/stock/exchange/return', data ).then(data => {
                          this.setState({
                            alert: "alert alert-info",
                            message: data.message
                          })
                          this.fetchPending()
                          
                        })
                      }
                    }>Return</button>
                    </td>
                  </tr>))
                }
              </table> 
    </div>
    </div>
    </div>
     </div>
    )
  }
}

export default Outgoing;
