import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { loggedIn } from './Session';
const Authed = loggedIn()


const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        Authed === true
        ? <Redirect to='/dashboard' />
        : <Component {...props} />
    )} />
  )

export default PublicRoute;