import React from 'react'

import NavBarAuthed from './Navbars/NavBarAuthed';
import { loggedIn } from '../helper/Session';
const Auth= loggedIn();
export default function Navbar() {
    if(!Auth)
    {
        return<div>
            
        </div>
    }else{
        return <NavBarAuthed/>
    }
}
