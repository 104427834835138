import React from 'react'
import SideNavAuthed from './Sidenav/SideNavAuthed';
import { loggedIn, getSessionPayload } from '../helper/Session';
import { linsks, generateNav } from '../helper/links';

const Auth = loggedIn();

console.log(getSessionPayload());//debig

export default function Sidenav(props) {
    if(Auth)
    {
        const links = getSessionPayload().access.Access === "Administrator" ? linsks : generateNav(getSessionPayload().PRIVILAGE);
       return <SideNavAuthed workspace={getSessionPayload().workspace} account={getSessionPayload().account}  links={links} />
    } else {
       return  <div>

        </div>
    }
}
