import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import Form from '../../../components/Form';
import {Api} from '../../../helper/Api';
import { ToastStore, ToastContainer } from 'react-toasts';
class Add extends Component {
  constructor()
  {
    super()
    this.state ={
      loader: "check",
      btnTxt: "SAVE",
      departments: [],
      departmentName:"",
      clientContact:"",
      stockData: []
    }

    this.onsubmit = this.onsubmit.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.handleType = this.handleType.bind(this)
    this.handleReturn = this.handleReturn.bind(this)
    this.handleDepartment = this.handleDepartment.bind(this)
    this.saveData = this.saveData.bind(this);
    this.savedAction = this.savedAction.bind(this);
  }
  onchange = (e) =>
  {
    this.setState({[e.target.name]: e.target.value});
  }

  savedAction(data)
  {
    ToastStore.success(data.message);
    this.setState({loader: "check", btnTxt:"SAVE"})
  }

  saveData(body)
  {
    const data = {
      method: 'post',
      body:JSON.stringify(body)
    }
    Api("/department/manage/add", data).then(data => {data.status === "success" ? this.savedAction(data) : ToastStore.error("An Error Occured !"); this.setState({loader: "check", btnTxt:"SAVE"})})
  }
  onsubmit = (e) => {
    e.preventDefault()
    this.setState({loader: "spinner fa-spin", btnTxt:"SAVING.."});
    document.getElementById('form').reset()
    const body = {
      token: "Bearer "+localStorage.getItem("appToken"),
      departmentName: this.state.departmentName
    }

    this.saveData(body)
  }

  getDepartments(e){
    var data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
    }

  Api("/department/manage/list", data).then(data => {this.setState({departments: data.map(data => ({value: data.DEPARTMENT_ID, label:data.DEPARTMENT}))})})
  }
  handleDepartment(selectedOption)
  {
    this.setState({departmentId: selectedOption.value});
  }
  handleReturn(selectedOption)
  {
    this.setState({returnStatus: selectedOption.value});
  }
  handleType(selectedOption)
  {
    this.setState({saveType: selectedOption.value});
  }
  componentWillMount()
  {
    this.getDepartments();
  }
  render(){


  return (
    <div>
      <ToastContainer store={ToastStore} />
      <div className="breadcrumb">
      <Link className="btn   btn-warning" to="/depts"> <span className="fa fa-arrow-left"></span> Back</Link>
      </div>
      <h3>ADD DEPARTMENT</h3>
      <div className="card">
          <div className="card-body">
              <Form btnTxt={this.state.btnTxt} loader={this.state.loader} submit={this.onsubmit} form={[{type: "input", name: "departmentName", label:"DEPARTMENT", ph: "Enter Department NAME", change:this.onchange}]}/>
          </div>
      </div>
    </div>
  )

}
}

export default Add;