import React, { Component } from 'react'
import {getSessionPayload} from '../../../helper/Session'
import { api } from '../../../helper/Api';
export default class ReportContent extends Component {
  render() {
      
    let results = this.props.data
        console.log(this.props.searchParameter);
        
        this.props.searchParameter.forEach(element => {
          results = this.props.data.length > 0 ? this.props.data.filter((jsonArray) => {
            return jsonArray[Object.keys(element)] === undefined ? "" : jsonArray[Object.keys(element)] === null ? "" : jsonArray[Object.keys(element)].toLowerCase()
              .indexOf(element[Object.keys(element)].toLowerCase()) !== -1
          }) : this.props.data;
        });
    
    
    
      
    return (
      
      <div className="content-wrapper">
     <div className="card">
     <div className="card-header">
     <div className="row">
     <div className="col-md-6">
                  <img src={api+"/uploads/"+getSessionPayload().workspaceId+".png"} style={{height: "70px", width:"70px"}} />
                </div>
     <div className="col-md-6 text-right">{getSessionPayload().workspace} <br/></div>
     </div>
     </div>
     <div className="card-body">
     <div className="text-center"><u><b>{this.props.title}</b></u></div>
     <small><b>Total Records: {results.length}</b></small>

    <div className="table-responsive">
    <table className="table table-bordered">
     <thead>
         <tr>
             {this.props.columns.map(data => ( <th>{data.col}</th> ))}
         </tr>
     </thead>
     <tbody>
         {results.map(data => (
             <tr>
                 {this.props.columns.map(row => (
                     <td>{data[row.row]}</td>
                 ))}
             </tr>
         ))}
     </tbody>
     </table>
    </div>
     </div>
     </div>
      </div>
     
    )
  }
}
