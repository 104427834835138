const env = "prod"

const api = env === "prod" ? "https://api.wesempire.co.ke/stock" : "http://localhost:8080"

const Api = (uri, data) =>
{
    var url = api + uri;
    return fetch(url, data)
    .then(Response => {return Response.json()});
}

export {Api, api} 