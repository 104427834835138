import React, {Component} from 'react'
import Tables from '../../../components/Tables';
import Select from 'react-select'
import { Link, Redirect } from 'react-router-dom';
import { Api } from '../../../helper/Api';


class View extends Component {
  constructor()
  {
    super()
    this.state = {
      stockData: [],
      deptsArray: [],
      sortParam: "",
    }
    this.getStocks = this.getStocks.bind(this);
    this.onSelect = this.onSelect.bind(this)
  }

  getStocks(){
    Api("/stock/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({stockData: data});
    })

    Api("/department/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({deptsArray: data});
  })



  }
  componentDidMount = () => {
    this.getStocks();
  }
  deleteRow(rowKeys)
  {
    return(<Redirect to="/stock/edit" />)
    
  }
  onSelect(selectedOption)
  {
    this.setState({sortParam: selectedOption.value}) 
    console.log(this.state.sortParam);
    
  }
  render(){
    let results = this.state.stockData
                            .filter(
                              (jsonArray) => {
                                var col = jsonArray.DEPARTMENT
                                col = col === null ? [] : col
                                return col.indexOf(this.state.sortParam) !== -1});
    const deps = this.state.deptsArray.map(data => ({value: data.DEPARTMENT, label: data.DEPARTMENT}))
  return (
    <div>
     <div className="breadcrumb">
     <Link to="/stock/new"  className="btn   btn-primary" > <span className="fa fa-plus-circle"></span>NEW STOCK</Link> 

     </div>
     <div className="form-group col-md-5">
     <label htmlFor="">CHOOSE DEPARTMENT</label>
      <Select options={deps} placeholder="SELECT DEPARTMENT" onChange={this.onSelect}  />
     </div>
     <h3>View Stocks</h3>
      <Tables search="STOCK_NAME" uri='stock' columns={[{ column: "STOCK ID", dataField: "STOCK_ID" }, { column: "STOCK NAME", dataField: "STOCK_NAME" }, { column: "DEPARTMENT", dataField: "DEPARTMENT" }, { column: "RETURN STATUS", dataField: "STATS" }, { column: "DATE OF CREATION", dataField: "DOC" }, { column: "STOCK_TYPE", dataField: "TYPES" }, {column:"UNITS",dataField:"UNITS"}, {column:"PRICE",dataField:"PRICE"}]} data={results} keyId="STOCK_ID"/>
    </div>
  )
  }
}

export default View;
