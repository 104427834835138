import React, {Component} from 'react'
import Tables from '../../../components/Tables';

import { Link, Redirect } from 'react-router-dom';
import { Api } from '../../../helper/Api';


class View extends Component {
  constructor()
  {
    super()
    this.state = {
      stockData: []
    }
    this.getStocks = this.getStocks.bind(this);
  }

  getStocks(){
    Api("/suppilers/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({stockData: data});
    })
  }
  componentDidMount = () => {
    this.getStocks();
  }
  deleteRow(rowKeys)
  {
    return(<Redirect to="/stock/edit" />)
    
  }
  render(){
  return (
    <div>
     <div className="breadcrumb">
     <Link to="/suppliers/new"  className="btn   btn-primary" > <span className="fa fa-plus-circle"></span>NEW SUPPLIER(S)</Link> 

     </div>
     <h3>View Suppliers</h3>
       <Tables search="SUPPLIER_NAME"  uri='suppliers' columns={[{column:"SUPPLIER ID",dataField:"SUPPLIER_ID"}, {column:"SUPPLIER NAME",dataField:"SUPPLIER_NAME"}, {column:"CONTACT",dataField:"SUPPLIER_CONTACT"}]} data={this.state.stockData} keyId="SUPPLIER_ID"/>
    </div>
  )
  }
}

export default View;
