import React from 'react'
import { Link } from 'react-router-dom';
import {getSessionPayload} from "../../helper/Session"
function logout()
{
  localStorage.clear();
  window.location.href ="/";
}
export default function NavBarAuthed(props) {
  return (
    <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          <img src="/images/logo.png"   /> dd
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="index.html">
          <img src="/images/logo.png"   />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center">
      <ul className="navbar-nav navbar-nav-left header-links d-none d-md-flex">
          <li className="nav-item">
            ACCESS ID :  <b>{getSessionPayload().workspaceId.toUpperCase()}</b>
          </li>
      </ul>
      <ul className="navbar-nav navbar-nav-right">
      <li className="nav-item dropdown d-none d-xl-inline-block">
      <a className="nav-link dropdown-toggle" id="UserDropdown" href="/" data-toggle="dropdown" aria-expanded="false">
              <span className="profile-text">Logged in as {getSessionPayload().username} </span>
              <img className="img-xs rounded-circle" src={props.avatar === undefined ? "/images/faces-clipart/pic-1.png": "avatar"}  alt="Profile image"/>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
             
              <a className="dropdown-item mt-2">
               <span className="fa fa-group"></span> {props.account === undefined ? "Administrator" : "Account Type"}
              </a>
              <Link to="/account" className="dropdown-item">
               <span className="fa fa-cog"></span> Manage My Account
              </Link>
              <a href="/" onClick={logout} className="dropdown-item">
               <span className="fa fa-lock"></span> Sign Out
              </a>
            </div>
          </li>
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )
}
