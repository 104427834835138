import React, { Component } from 'react'
import { getSessionPayload } from '../../helper/Session';

export default class Account extends Component {
    constructor()
    {
        super()
        this.state = {
            username: "",
        }
    }
    componentWillMount()
    {
        var user_id = getSessionPayload().userId;
    }
  render() {
    return (
      <div className="main-panel">
        <div className="content-wrapper">
        <h1><span className="fa fa-wrench"></span>Account Settings</h1>

        <hr/>

        <div className="card">
            <div className="card-body">
            <form action="" className="col-md-6">
            <div className="class-header">EDIT YOUR ACCOUNT INFORMATION BELOW</div><br/>
           <div className="form-group">
           <label htmlFor="">USERNAME</label>
            <input type="text" className="form-control" name="username"/>
           </div>
           <div className="form-group">
           <label htmlFor="">PASSWORD</label>
            <input type="text" className="form-control" name="username"/>
           </div>
           <div className="form-group">
           <label htmlFor="">CONFIRM PASSWORD</label>
            <input type="text" className="form-control" name="username"/>
           </div>
           <div className="text-center">
           <button className="btn btn-primary">SAVE</button>
           </div>            
            </form>
            </div>
        </div>
        </div>
      </div>
    )
  }
}
