import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import Accounts from './AccessApp/Accounts';
import Privilages from './AccessApp/Privilages';
import AccountType from './AccessApp/AccountType';
export default class Access extends Component {
  
  render() {
    return (
<div className="main-panel">
        <div className="content-wrapper">
        <h1><span className="fa fa-lock"></span><small><span className="fa fa-group"></span></small> ACCESS CONTROL</h1>

        <hr/>
       <Switch>
       <Route path={this.props.match.path} exact component={Accounts} />
        <Route path={this.props.match.path+"/accounts"} component={Privilages} />
        <Route path={this.props.match.path+"/groups"} component={AccountType} />
       </Switch>
        </div>
        </div>
        
    )
  }
}
