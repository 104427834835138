import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import Form from '../../../components/Form';
import {Api} from '../../../helper/Api';
import { ToastStore, ToastContainer } from 'react-toasts';
import Select from 'react-select';
class Incoming extends Component {
  constructor()
  {
    super()
    this.state={
      stockData:[],
      load: "check",
      btn: "SAVE",
      selectedStock: [],
      clientsData: [],
      clientId: "",
      stockId: "",
      returnState: "",
      stockType:"",
      Product: "",
      amount: "",
      message: "",
      products: [],
      sortParam: "",
      deptsArray: [],
      region: "",
    }
    this.getStocks = this.getStocks.bind(this);
    this.choosedStock = this.choosedStock.bind(this)
    this.onchange = this.onchange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.handleClientChang = this.handleClientChang.bind(this)
  }


  handleClientChang(selectedOption)
  {
    this.setState({clientId: selectedOption.value});    
  }
  getStocks(){
    Api("/stock/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({stockData: data});
    })
    Api("/clients/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({clientsData: data});
    })
    Api("/department/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({deptsArray: data});
  })
  }

  componentWillMount()
  {
    this.getStocks()
  }
  choosedStock(selectedOption)
  {
    this.setState({load: "spinner fa-spin", btn:'Fetching Data', stockType: selectedOption.type, stockId: selectedOption.value, returnState:selectedOption.returns})
    Api("/stock/manage/find", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"), stockId:selectedOption.value})}).then(data => {this.setState({selectedStock: data});
    this.setState({load: "check", btn:"SAVE"})
    })
    if(selectedOption.type !== 'grouped')
    {
      Api("/stock/manage/individual", { method: 'POST', body: JSON.stringify({ token: "Bearer " + localStorage.getItem("appToken"), stockId: selectedOption.value }) }).then(data => {
        const response = data
        this.setState({ load: "check", btn: "SAVE", products: response.quantity })
      })
    }
    this.setState({
      stockType: selectedOption.type
    })
  }

  onchange(e)
  {
    this.setState({[e.target.name]: e.target.value})
  }
  onSubmit(e)
  {
    e.preventDefault()
    this.setState({load: "spinner fa-spin", btn: "Restocking..."})
    document.getElementById('form').reset()
    var body = {
      stockId: this.state.stockId,
      product: this.state.amount,
      clientId: this.state.clientId,
      returnState: this.state.returnState,
      stockType: this.state.stockType,
      region: this.state.region,
      token: "Bearer "+localStorage.getItem("appToken")
    }
    

    const data = {
      method: 'POST',
      body:JSON.stringify(body)
    }
    Api("/stock/exchange/out", data).then(data => {ToastStore.success(data.message); this.setState({load: "check", btn:"SAVE"})})
  }
  onSelect(selectedOption)
  {
    this.setState({sortParam: selectedOption.value}) 
    console.log(this.state.sortParam);
    
  }
  render()
  {
    let results = this.state.stockData
                            .filter(
                              (jsonArray) => {
                                var col = jsonArray.DEPARTMENT
                                col = col === null ? [] : col
                                return col.indexOf(this.state.sortParam) !== -1});
    const options = results.map(data => ({value: data.STOCK_ID, label: data.STOCK_NAME, type: data.TYPES, returns: data.STATS }))
    const cliOpts = this.state.clientsData.map(data => ({value: data.CLIENT_ID, label: data.CLIENT_NAME, type: data.TYPES}))
   console.log(this.state);
    const values = this.state.products.map(data => ({ value: data.PRODUCT_ID, label: data.PRODUCT_ID }))
const deps = this.state.deptsArray.map(data => ({value: data.DEPARTMENT, label: data.DEPARTMENT}))
    return(
      <div>
        <ToastContainer store={ToastStore} />
      <div className="breadcrumb">
      <Link to="exchange/recieve" className="btn btn-primary"> <span className="fa fa-inbox"></span> RETURN PENDING </Link>
    </div>
     <div className="card">
     <div className="card-body">
     <div className="form-group col-md-6">
     <label htmlFor="">CHOOSE DEPARTMENT</label>
      <Select options={deps} placeholder="SELECT DEPARTMENT" onChange={this.onSelect}  />
     </div>
     <form action="" onSubmit={this.onSubmit} id="form" className="form-group ">
     <div className="form-group col-md-6">
     <label htmlFor="">SELECT RESTOCK ITEM</label>
     <Select placeholder="SELECT STOCK" options={options} onChange={this.choosedStock} />
     {this.state.selectedStock.map(data => (<div>{data.STOCK_SAVE_TYPE === "grouped" ? <div><label>Enter Amount For This Stock</label><input name="amount" onChange={this.onchange} className="form-control"  /></div> :  <div onLoad={()=>{console.log("sdsds");
     }}><label>Enter Choose Stock For Exchange</label><Select options={values} onChange={(
       (selected) => {
         this.setState({amount: selected.value})
       }
     )} /></div>}</div>))} <br/>
    
     </div>
    
   <div className="form-group col-md-6">
     <label htmlFor="">SELECT CLIENT</label>
     <Select placeholder="SELECT CLIENT" options={cliOpts} onChange={this.handleClientChang} />
     </div>
     <div className="form-group col-md-6">
     <label htmlFor="">ENTER REGION</label>
      <input type="text" placeholder="Enter Region" name="region" onChange={this.onchange} className="form-control"/>
     </div>
     <div className="form-group col-md-6">
     <button className="btn btn-success" > <span className={"fa fa-"+this.state.load}></span> {this.state.btn}</button>
    </div>
      </form> 
     </div>
     </div>    
     </div>  
    )
  }
}

export default Incoming;