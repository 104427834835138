import React from 'react'
import { Link } from 'react-router-dom';
import { Api } from '../../../helper/Api';
import { ToastStore, ToastContainer } from 'react-toasts';

export default function Delete(props) {
  onsubmit = (e) =>
  {
    e.preventDefault()
    Api('/department/manage/delete', {method: 'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"), deleteKey: props.match.params.deptId})}).then(data => {ToastStore.error(data.message);})
  }
  return (
    <div>
      <ToastContainer store={ToastStore} />
    <div className="breadcrumb">
    <Link className="btn   btn-warning" to="/depts"> <span className="fa fa-arrow-left"></span> Back</Link>
    </div>
    <div className="card text-center">
    <h4>Do You want to Delete This ?</h4>
    <form onSubmit={onsubmit}>
    <button className="btn btn-danger">Yes</button> <Link className="btn btn-info" to="/depts" >No</Link> <hr/></form>
    </div>
  </div>
  )
}
