import React, { Component } from 'react'
import {Api} from '../../helper/Api';

export default class Dashboard extends Component {
  constructor()
  {
    super()
    this.state = {
      status: {
        stocks: "Fetching...",
        product_individual: "Fetching...",
        product_grouped: "Fetching..."
      }
    }
    this.getStatus = this.getStatus.bind(this);
  }
  getStatus()
  {
    var data = {
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")}),
      method: 'POST'
    }
   Api("/checkpoints/dashboard/status", data).then(data => {this.setState({status:data.data })}); 
  }

  componentWillMount()
  {
    this.getStatus()
  }
  render() {
    return (
      <div className="main-panel ovef">
        <div className="content-wrapper">
        <h1> <span className="fa fa-dashboard"></span> Dashboard</h1><hr/>
        <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
              <div className="card card-statistics">
                <div className="card-body">
                  <div className="clearfix">
                    <div className="float-left">
                      <i className="fa fa-tags text-danger icon-lg"></i>
                    </div>
                    <div className="float-right">
                      <p className="mb-0 text-right">STOCK REMAINING</p>
                      <div className="fluid-container">
                        <h3 className="font-weight-medium text-right mb-0">{this.state.status.stocks}</h3>
                      </div>
                    </div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <i className="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> All The Stocks Remaining Registered In The System
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
              <div className="card card-statistics">
                <div className="card-body">
                  <div className="clearfix">
                    <div className="float-left">
                      <i className="fa fa-tint  text-info icon-lg"></i>
                    </div>
                    <div className="float-right">
                      <p className="mb-0 text-right">INDIVIDUAL PRODUCT REMAINING</p>
                      <div className="fluid-container">
                        <h3 className="font-weight-medium text-right mb-0">{this.state.status.product_individual}</h3>
                      </div>
                    </div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <i className="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> Individual product Count
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 grid-margin stretch-card">
              <div className="card card-statistics">
                <div className="card-body">
                  <div className="clearfix">
                    <div className="float-left">
                      <i className="fa fa-sitemap text-primary icon-lg"></i>
                    </div>
                    <div className="float-right">
                      <p className="mb-0 text-right">GROUPED PRODUCT REMAINING</p>
                      <div className="fluid-container">
                        <h3 className="font-weight-medium text-right mb-0">{this.state.status.product_grouped}</h3>
                      </div>
                    </div>
                  </div>
                  <p className="text-muted mt-3 mb-0">
                    <i className="mdi mdi-alert-octagon mr-1" aria-hidden="true"></i> Sum of All grouped products Remaining
                  </p>
                </div>
              </div>
            </div>
            
        </div>

        </div>
      </div>
    )
  }
}
