import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import { Api } from '../../../helper/Api';
export default class Privilages extends Component
{
    constructor()
    {
        super()
        this.state = {
            userData: [],
            deleteId: null,
            alert: false,
            message: false
        }
        this.getUsers = this.getUsers.bind(this);
    }

    getUsers()
    {
        var data = {
            method: 'POST',
            body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
        }

        Api("/auth/accounts/list", data).then(data => {this.setState({userData: data})})
    }
    componentWillMount()
    {
        this.getUsers()
    }
    render()
    {
        return(
        <div>
            <h3><span className="fa fa-list"></span> LIST OF ACCOUNTS</h3>
            <div className="card-body">   <Link className="btn btn-primary" to="/access">MANAGE ACCOUNTS</Link>  <Link className="btn btn-primary" to="/access/groups">MANAGE GROUPS</Link></div>
            <div className="card">
            <div className="card-body">
            <div className={this.state.alert === true ? "alert alert-danger" : "" }>{this.state.message === true ? <div> <b>ARE YOU SURE YOU WANT TO DELETE THIS USER ?</b> <br/><button onClick={() => {
                //delete
                var data = {
                    method: 'POST',
                    body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"), deleteKey: this.state.deleteId})
                }
                Api("/auth/accounts/delete", data); 
                this.setState({alert: false, message: false})
            }} className="btn btn-warning"> Ok </button> <button className="btn btn-primary" onClick={() => {this.setState({alert: false, message: false})}}> Cancel</button></div> : <div></div>}</div>
            <table className="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>USER ID</th>
                    <th>USERNAME</th>
                    <th>GROUP</th>
                    <th>ACTIONS</th>
                </tr>
            </thead>
            <tbody>
                {this.state.userData.map(data => (
                    <tr>
                        <td>{data.USER_ID}</td>
                        <td>{data.USERNAME}</td>
                        <td>{data.ACCESS_NAME}</td>
                        <td><button onClick={() => {this.setState({deleteId: data.USER_ID, alert:true, message: true}) ; console.log(data.USER_ID);
                        }} className="btn btn-danger"><span className="fa fa-trash"> </span> DELETE USER</button></td>
                    </tr>
                ))}
            </tbody>
            </table>
            </div>
            </div>
            </div>
            
        )
    }
}