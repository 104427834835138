import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import { Api } from '../../../helper/Api'
import { ToastStore, ToastContainer } from 'react-toasts'
export default class Accounts extends Component
{
    constructor()
    {
        super()
        this.state = {
            accounts: [],
            accessLevels: [],
            username: "",
            password: "",
            accessId: "",
        }

        this.getAccess = this.getAccess.bind(this);
        this.onchange = this.onchange.bind(this);
        this.onsubmit = this.onsubmit.bind(this);
        this.handeSelect = this.handeSelect.bind(this);
        this.getAccounts = this.getAccounts.bind(this);
    }
    getAccounts()
    {
        var data = {
            method: 'POST',
            body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
        }

        Api("/auth/accounts/list", data).then(data => {this.setState({accounts: data})})
    }
    handeSelect(selectedOptions)
    {
        this.setState({accessId: selectedOptions.value})
    }
    onchange = (e) =>{
        this.setState({[e.target.name]: e.target.value});
    }
    getAccess()
    {
        var data = {
            method: 'POST',
            body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
        }
        Api('/access/manage/list', data).then(data => {this.setState({accessLevels: data})})
    }

    onsubmit(e){
        e.preventDefault()
       var body = JSON.stringify({
           token: "Bearer "+localStorage.getItem("appToken"),
           username: this.state.username,
           password: this.state.password,
           accessId: this.state.accessId
       }) 

       const data = {
           method: 'POST',
           body:body
       }

       Api("/auth/accounts/add", data).then(data => {ToastStore.success(data.message)})
    }

    componentWillMount()
    {
        this.getAccess();
        this.getAccounts();
    }
    render()
    {
        const options = this.state.accessLevels.map(data => ({value: data.ACCESS_ID, label:data.ACCESS_NAME}));
        const acOpts = this.state.accounts.map(data => ({value: data.USER_ID, label:data.USERNAME}));
        return(
            <div>
                <ToastContainer store={ToastStore} />
                <h3><span className="fa fa-user"></span> ACCOUNTS MANAGEMENT</h3>
<div className="card-body">
<Link className="btn btn-primary" to="/access/accounts">VIEW ACCOUNTS</Link>  <Link className="btn btn-primary" to="/access/groups">MANAGE GROUPS</Link>
</div>
            <div className="card">
                <div className="card-header">ADD NEW ACCOUNT</div>
                <div className="card-body">
                <form onSubmit={this.onsubmit}>
                <div className="form-group col-md-6">
                <label htmlFor="">USERNAME</label>
                <input type="text" className="form-control" name="username" onChange={this.onchange} id=""/><br/>
                <label htmlFor="">PASSWORD</label>
                <input type="text" className="form-control" name="password" onChange={this.onchange} type="password" id=""/><br/>
               <label htmlFor=""> CHOOSE GROUP</label>
                <Select options={options} onChange={this.handeSelect} />
                <br/>
                <button className="btn btn-success"><span className="fa fa-check"></span> SAVE</button>
                </div>
                </form>
                </div>
            </div>

            <div className="card">
                <div className="card-header">RESTORE ACCOUNT</div>
                <div className="card-body">
                <form action="">
                <div className="form-group col-md-6">
                <label htmlFor=""> CHOOSE USER</label>
                <Select options={acOpts} /><br/>
                <label htmlFor="">SET UP PASSWORD</label>
                <input type="text" className="form-control" name="" id=""/><br/>
                
                <br/>
                <button className="btn btn-success"><span className="fa fa-check"></span> SAVE</button>
                </div>
                </form>
                </div>
            </div>
        </div>
        )   }
}