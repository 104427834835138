import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ReactToPrint from 'react-to-print';
import ReportContent from './Report/Stocks';
import Select from 'react-select'
import {Api} from '../../helper/Api'
const options = [{
  value: "STOCK",
  label: "STOCK REPORT"
},{
  value: "DEPARTMENT",
  label: "DEPARTMENT REPORT"
},{
  value: "CLEINTS",
  label: "CLIENTS REPORT"
},{
  value: "SUPPLIERS",
  label: "SUPPLIERS REPORT"
},{
  value: "EXCHANGE",
  label: "CHECK IN AND OUT REPORTS"
}]
export default class Reports extends Component {
  constructor()
  {
    super()
    this.state = {
      data: [],
      columns: [{col: "STOCK ID", row: "STOCK_ID"}, {col: "STOCK NAME", row: "STOCK_NAME"}],
      title: "STOCK REPORT",
      customTitle: "",
      search: [],
      searchParameter: "",
      searchparams: [{"": ""}],
      filterText: "STOCK NAME",
      mode: ""
    }
    
    this.onchangeText = this.onchangeText.bind(this)
    this.radioToggle = this.radioToggle.bind(this)
    this.changeMode = this.changeMode.bind(this)
    this.exInt = this.exInt.bind(this)
  }
componentWillMount()
{
  const {mode} = this.state
  this.changeReports(mode)
  
}



  onchangeText(e)
  {
    var params = this.state.searchparams
    var name = e.target.name
    //find obj
    params.forEach(element => {
      if(Object.keys(element)[0] === e.target.name)
      {
        var index = -1
        var position = params.indexOf(params.find(function(jsons, i){
          if(Object.keys(jsons)[0] === e.target.name)
          {
            index = i;
            return i;
          }
        }))
      }

      console.log(position)

      params[position] = {[e.target.name] : e.target.value}
    });
    //update object
    this.setState({searchparams: params})
    
  }
  stockInt(){
    //fetch and set data
    Api("/stock/manage/report", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({data: data});
    })

    const columns = [{ col: "STOCK ID", row: "stockId" }, { col: "STOCK NAME", row: "stockName" }, { col: "QUANTITY REMAINING", row: "Quantity" }, { col: "DEPARTMENT", row: "DEPARTMENT" }, { col: "UNITS", row: "units" }, { col: "PRICE", row: "price" }]
    this.setState({ columns: columns, search: [], title: "STOCK REPORT"})
  }
  deptsInt(){
    Api("/department/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({data: data});
    })
    const columns = [{col:"DEPARTMENT ID",row:"DEPARTMENT_ID"}, {col:"DEPARTMENT NAME",row:"DEPARTMENT"}]
    this.setState({columns: columns, search: [], title: "DEPARTMENT REPORT"})
  }
  suppliersInt(){
    Api("/suppilers/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({data: data});
    })
    const columns=[{col:"SUPPLIER ID",row:"SUPPLIER_ID"}, {col:"SUPPLIER NAME",row:"SUPPLIER_NAME"}, {col:"CONTACT",row:"SUPPLIER_CONTACT"}]
    this.setState({columns: columns, search:[], title: "SUPPLIERS REPORT"})
  }
  clientsInt(){
    Api("/clients/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({data: data});
  })
  const columns=[{col:"CLIENT ID",row:"CLIENT_ID"}, {col:"CLIENT NAME",row:"CLIENT_NAME"}, {col:"CONTACT INFORMATION",row:"CONTACT"}]
  this.setState({columns: columns, search:[], title: "CLIENTS REPORT"})
  }
 acInt(){
  var data = {
    method: 'POST',
    body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
}


Api("/auth/accounts/list", data).then(data => {this.setState({userData: data})})
const columns = [{col: "USER ID", row: "USER_ID"}, {col: "USERNAME", row: "USERNAME"}, {col: "RANK", row: "ACCESS_NAME"}]
this.setState({columns: columns, search:[], title: "ACCOUNTS REPORT"})
  }
  exInt()
{
    Api("/stock/exchange/report", { method: 'POST', body: JSON.stringify({ token: "Bearer " + localStorage.getItem("appToken") }) }).then(data => {
      this.setState({ data: data });
    })
  this.setState({data: []})
  const columns=[{col:"STOCK NAME ",row:"STOCK_NAME"}, {col:"CLIENT",row:"CLIENT_NAME"}, {col:"AMOUNT",row:"AMOUNT"} ,{col: "ACTION", row: "ACTION"}, {col:"TIME", row:"TIMESTAMP"}, {col:"REGION", row: "REGION"}]
  this.setState({columns: columns, search:[], title: "CHECKOUT REPORT"})
}

  changeMode(selectedOption)
  {
    const mode = selectedOption.value;
    switch (mode) {
      case "STOCK":
        this.stockInt()
        break;
      case "DEPARTMENT":
        this.deptsInt()
        break;
      case "SUPPLIERS":
        this.suppliersInt()
        break;
      case "ACCOUNTS":
        this.acInt()
        break;
      case "CLEINTS":
        this.clientsInt()
        break;
      case "EXCHANGE":
        this.exInt()
        break;
      
      default:
      this.stockInt()
        break;
    }
  }
  changeReports(mode)
{
  switch (mode) {
    case "STOCK":
      this.stockInt()
      break;
    case "DEPARTMENT":
      this.deptsInt()
      break;
    case "SUPPLIERS":
      this.suppliersInt()
      break;
    case "ACCOUNTS":
      this.acInt()
      break;
    case "CLIENTS":
      this.clientsInt()
      break;
    
    default:
    this.stockInt()
      break;
  }
 
  
}
  radioToggle(e)
  {
    var checked = e.target.checked

    if(checked === true)
    {
      var search = this.state.search
      var params = this.state.searchparams
      params.push({[e.target.value] : ""})
      search.push(e.target.value)
      
      this.setState({
        search: search,
        searchparams: params
      })
      
    } else {

      var params = this.state.searchparams
      var index = -1
      var datahits = params.indexOf(params.find(function (jsons, i) {
        if (Object.keys(jsons)[0] === e.target.name) {
          index = i;
          return i;
        }
      }))
      params.splice(datahits, 1)
      this.setState({
        searchparams: params
      })
      var hits = this.state.search.indexOf(e.target.value)

      if (hits !== -1) {
        var search = this.state.search
        search.splice(hits, 1)

        this.setState({
          search: search
        })
      }
    }

    

    

      
      
    }

  render() {
    console.log(this.state.search);
    
    return (
<div className="main-panel">
        <div className="content-wrapper">
        <h1><span className="fa fa-book" ></span> GENERATE REPORTS</h1>
        <hr/>
        <div className="card-body col-md-6">
       <div className="form-group">
       <Select onChange={this.changeMode} options={options} placeholder="SELECT REPORT TYPE" /></div>
        <div className="form-group">
        ENTER CUSTOM REPORT TITLE:
        <input type="text" className="form-control" onChange={this.onchangeText} placeholder="TITLE" name="customTitle" id=""/> <br/>
        FILTER BY:
        <table className="table table-bordered">
        {this.state.columns.map(data => (
          <tr>
            <td htmlFor="">{data.col}</td>
            <td>  <input type="checkbox" name="filterText" value={data.row} id={data.col} onChange={this.radioToggle} /> </td> 
          </tr>
        ))}
              </table>
        {
          this.state.search.map(data=>(
           <div>
              FILTER BY {data}:
              <input type="text" className="form-control" onChange={this.onchangeText} name={data} placeholder="FILTER" id="" />

           </div>
          ))
        } </div>
        </div>
        <div className="card-body">
        <ReactToPrint
          trigger={() => <button className="btn btn-success" href="#"> <span className="fa fa-file-pdf-o"></span> SAVE PDF</button>}
          content={() => this.componentRef}
        /> <br/><br/>
        <b>Review Report</b>
          <ReportContent searchParameter={this.state.searchparams} search={this.state.search} columns={this.state.columns} data={this.state.data} title={this.state.customTitle === "" ? this.state.title : this.state.customTitle} ref={el => (this.componentRef = el)} />
        </div>
        </div>
        </div>
        
    )
  }


}
