import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Select from 'react-select'
import { Api } from '../../helper/Api';
import { ToastContainer, ToastStore } from 'react-toasts';
import {setSession} from "../../helper/Session";

class Home extends Component {
  constructor() {
    super()
    this.state = {
      workspaces: [],
      username: "",
      password: "",
      workspaceId: "",
      workspace: "",
      loading: ""
    }

    this.onsubmit = this.onsubmit.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  validateData(body) {
    if (body.username === "" || body.password === "" || body.workspaceId === "") {
      return false
    } else {
      return true
    }
  }
  loginSuccess(data){
    ToastStore.success(data.message);
    setSession(data.token);
    window.location.href = "/dashboard";
  }
  onsubmit(e) {
    e.preventDefault();
    this.setState({ loading: "fa fa-spinner fa-spin" });
    const states = this.state
    const body = {
      username: states.username,
      password: this.state.password,
      workspaceId: states.workspaceId,
      workspace: states.workspace
    }

  

    if (this.validateData(body)) {
      //ajax
      const data = {
        method: 'POST',
        body: JSON.stringify(body)
      }
      Api("/auth/accounts/login", data).then(data => {data.status === "error" ? ToastStore.error(data.message) : this.loginSuccess(data)})
    } else {
      ToastStore.error("Please Fill In All Fields");
      this.setState({loading: ""})
    }
  }
  getWorkSpaces() {
    var data = {
      method: 'GET',
    }
    Api("/workspace/manage/list", data).then(data => { this.setState({ workspaces: data }) })
  }

  onchange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  selectChange = (selectedValue) => {
    this.setState({ workspaceId: selectedValue.value, workspace:selectedValue.label});
  }

  componentWillMount() {
    this.getWorkSpaces()
  }

  render() {
    const options = this.state.workspaces.map(data => ({ value: data.WorkspaceId, label: data.Workspace }))
    return (
      <div className="content-wrapper  align-items-center auth auth-bg-1 theme-one">
        <div className="">
          <ToastContainer store={ToastStore} />
          <div className="">
            <div className="row">
              <div className="mx-auto">
                <div className="auto-form-wrapper">

                  <form onSubmit={this.onsubmit}>
                    <div className="form-group">
                      <label className="label">Select Workspace</label>
                      <Select options={options} onChange={this.selectChange} />
                    </div>
                    <div className="form-group">
                      <label className="label">Username</label>
                      <div className="input-group">
                        <input name="username" onChange={this.onchange} type="text" className="form-control" placeholder="Username" />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="label">Password</label>
                      <div className="input-group">
                        <input type="password" name="password" onChange={this.onchange} className="form-control" placeholder="*********" />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="mdi mdi-check-circle-outline"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button className="btn btn-primary submit-btn btn-block">Login <span className={this.state.loading}></span></button>
                    </div>
                    <div className="text-block text-center my-3">
                      <span className="text-small font-weight-semibold">Not a member ?</span>
                      <Link to="/register" className="text-black text-small">Create new account</Link>
                    </div>
                  </form>
                </div>
                <ul className="auth-footer">
                  <li>
                    <a href="/">Conditions</a>
                  </li>
                  <li>
                    <a href="/">Help</a>
                  </li>
                  <li>
                    <a href="/">Terms</a>
                  </li>
                </ul>
                <p className="footer-text text-center">copyright © {Date().split(" ")[3]} WesEmpire. All rights reserved.</p>
              </div>
            </div>
          </div>


        </div>

      </div>

    )
  }
}

export default Home;