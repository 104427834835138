import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import View from './ClientApp/View';
import Edit from './ClientApp/Edit';
import Delete from './ClientApp/Delete';
import Add from './ClientApp/Add';
export default class Client extends Component {
  
  render() {
    return (
<div className="main-panel">
        <div className="content-wrapper">
        <h1><span className="fa fa-group"></span> CLEINTS MANAGEMENT</h1>

        <hr/>
      <Switch>
       <Route exact path={this.props.match.path} component={View} />
       <Route path={`${this.props.match.path}/new`} component={Add} />
      <Route path={`${this.props.match.path}/Edit/:clientId`} component={Edit} />
      <Route path={`${this.props.match.path}/Delete/:clientId`} component={Delete} />
      </Switch>
        </div>
        </div>
        
    )
  }
}
