import React from 'react'
import { Link } from 'react-router-dom';
import { getSessionPayload } from '../../helper/Session';
function logout()
{
  localStorage.clear();
  window.location.href ="/";
}

export default function SideNavAuthed(props) {
  return (
    <nav className="fixed sidebar sidebar-offcanvas ovef" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <div className="nav-link">
              <div className="user-wrapper">
                <div className="profile-image">
                  <img src="/images/faces-clipart/pic-1.png" />
                </div>
                <div className="text-wrapper">
                  <p className="profile-name">{props.workspace}</p>
                  <div>
                    <small className="designation text-muted"> {getSessionPayload().access.Access} /  
              </small>
                   <b>Active    </b> <span className={navigator.onLine === true ? "status-indicator online" : "status-indicator"}></span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <i className="menu-icon fa fa-dashboard"></i>
              <span className="menu-title">DASHBOARD</span>
            </Link>
          </li>
          <li className="nav-item">
           <a href="#" onClick={logout} className="nav-link">
               <i className="menu-icon fa fa-lock"></i> <span className="menu-title">Sign Out</span>
              </a>
              </li>
          {props.links.map((data) => (
              <li className="nav-item">
              <Link className="nav-link" to={data.page}>
                <i className={"menu-icon fa fa-"+data.icon}></i>
                <span className="menu-title">{data.label}</span>
              </Link>
            </li>
          ))}
           
              
        </ul>
      </nav>
      
  )
}
