import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import Form from '../../components/Form';
import Select  from 'react-select';
import { Api } from '../../helper/Api';
import { ToastStore, ToastContainer } from 'react-toasts';

export default class Shipment extends Component {
  constructor()
  {
    super()
    this.state={
      stockData:[],
      load: "check",
      btn: "SAVE",
      selectedStock: [],
      products: "",
      type: "",
      stockId: ""
    }
    this.getStocks = this.getStocks.bind(this);
    this.choosedStock = this.choosedStock.bind(this)
    this.onchange = this.onchange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  getStocks(){
    Api("/stock/manage/list", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})}).then(data => {this.setState({stockData: data});
    })
  }

  componentWillMount()
  {
    this.getStocks()
  }
  choosedStock(selectedOption)
  {
    this.setState({load: "spinner fa-spin", btn:'Fetching Data', type: selectedOption.type, stockId: selectedOption.value})
    Api("/stock/manage/find", {method:'POST', body:JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"), stockId:selectedOption.value})}).then(data => {this.setState({selectedStock: data});
    this.setState({load: "check", btn:"SAVE"})
    })
    
  }

  onchange(e)
  {
    this.setState({[e.target.name]: e.target.value})
  }
  onSubmit(e)
  {
    e.preventDefault()
    this.setState({load: "spinner fa-spin", btn: "SAVING..."})
    document.getElementById('form').reset()
    var body = {
      stockId: this.state.stockId,
      type: this.state.type,
      products: this.state.products,
      token: "Bearer "+localStorage.getItem("appToken")
    }

    const data = {
      method: 'POST',
      body:JSON.stringify(body)
    }
    Api("/stock/inventory/add", data).then(data => {ToastStore.success(data.message); this.setState({load: "check", btn:"SAVE"})})
  }
  render() {
    const options = this.state.stockData.map(data => ({value: data.STOCK_ID, label: data.STOCK_NAME, type: data.TYPES}))
    
    
    return (
<div className="main-panel">
<ToastContainer store={ToastStore} />
        <div className="content-wrapper">
        <h1><span className="fa fa-truck"></span> SHIP IN STOCKS</h1>

        <hr/>
       <div className="card">
       <div className="card-body">
       <form action="" onSubmit={this.onSubmit} id="form" className="form col-md-6">
       <div className="form-group ">
       <label htmlFor="">SELECT RESTOCK ITEM</label>
       <Select placeholder="SELECT STOCK" options={options} onChange={this.choosedStock} />
       </div>
       {this.state.selectedStock.map(data => (<div>{data.STOCK_SAVE_TYPE === "grouped" ? <div><label>Enter Amount For This Stock</label><input name="products" onChange={this.onchange} className="form-control" type="number" /></div> : <div><label>Enter Product For Stock</label><input type="text" name="products" className="form-control" onChange={this.onchange} /></div>}</div>))} <br/>
       <button className="btn btn-primary" > <span className={"fa fa-"+this.state.load}></span> {this.state.btn}</button>
       </form> 
       </div>
       </div>      
        </div>
        </div>
        
    )
  }
}
