import React, { Component } from 'react'

import Navbar from '../components/Navbar';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Home from '../pages/public/Home';
import Sidenav from '../components/Sidenav';
import Register from './public/Register';
import Dashboard from './app/Dashboard';
import { loggedIn } from '../helper/Session';
import PrivateRoute from '../helper/SecureRoute';
import Stocks from './app/Stocks';
import PublicRoute from '../helper/PublicRoute';
import Client from './app/Client';
import Supplier from './app/Supplier';
import Departments from './app/Departments';
import Access from './app/Access';
import Reports from './app/Reports';
import Restock from './app/Restock';
import Approve from './app/Approve';
import Shipment from './app/Shipment';
import Workspace from './app/Workspace';
import Exchange from './app/Exchange';
import Account from './app/Account';

const Authed = loggedIn();

export default class Entry extends Component {
  render() {
    return (
   <Router>
       <div>
       <Navbar />
       <div className={Authed === true ? "container-fluid page-body-wrapper":"container-fluid page-body-wrapper full-page-wrapper auth-page"}>
       <div className="sid">
       <Sidenav/></div>
       <PublicRoute exact path="/" component={Home} />
       <PublicRoute path="/register" component={Register} />
       <PrivateRoute path="/dashboard" component={Dashboard} />
       <PrivateRoute path="/stock" component={Stocks} /> 
       <PrivateRoute path="/clients" component={Client} /> 
       <PrivateRoute path="/suppliers" component={Supplier} /> 
       <PrivateRoute path="/depts" component={Departments} /> 
       <PrivateRoute path="/access" component={Access} /> 
       <PrivateRoute path="/reports" component={Reports} /> 
       <PrivateRoute path="/reorder" component={Restock} /> 
       <PrivateRoute path="/approve" component={Approve} /> 
       <PrivateRoute path="/shipments" component={Shipment} /> 
       <PrivateRoute path="/settings" component={Workspace} /> 
       <PrivateRoute path="/exchange" component={Exchange} />
       <PrivateRoute path="/account" component={Account} /> 
       
       </div>
       </div>
   </Router>
    )
  }
}
