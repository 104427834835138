import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { loggedIn } from './Session';
const Authed = loggedIn()


const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        Authed === true
        ? <Component {...props} />
        : <Redirect to='/' />
    )} />
  )

export default PrivateRoute;