import React, {
    Component
} from 'react';
import Entry from './pages/Entry';

class App extends Component {
    render() {
        return ( <
            Entry / >
        );
    }
}

export default App;