import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import {ToastContainer, ToastStore} from 'react-toasts';
import { Api } from '../../helper/Api';
class Register extends Component {
  constructor()
  {
    super()
    this.state = {
      workspaceName: "",
      username: "",
      password: "",
      confirm: "",
      loading: ""
    }

    this.onchange = this.onchange.bind(this);
    this.getWorkSpaces = this.getWorkSpaces.bind(this);
  }

  validateData(data){
    if(data.username === "" || data.p1 === "" || data.confirm === "" || data.workspaceName === ""){
      ToastStore.error("We Cannot Process Empty Fields..Make Sure To Fill Them")
      return false
    } else {
      if(data.p1 !== data.confm)
      {
        ToastStore.error("The Passwords Do Not Match")
        return false
      } else {
        return true
      }
    }
  }
  onsubmit = (e) =>
  {
    e.preventDefault()
    this.setState({loading: "fa fa-spinner fa-spin"})
    const states = this.state
    const processData = {
      workspaceName: states.workspaceName,
      username: states.username,
      p1: states.password,
      confm: states.confirm,
    }

    if(this.validateData(processData))
    {
      var body = {
        workspace: states.workspaceName,
        username: states.username,
        password: states.password
      }

      var data = {
        method: 'POST',
        body: JSON.stringify(body)
      }

      Api("/workspace/manage/add", data).then(data => {ToastStore.success(data.message); this.setState({loading: ""}); window.location.href="/"})

      
    } else {
      this.setState({loading: ""})
    }

  }

  onchange = (e) =>
  {
    this.setState({[e.target.name]: e.target.value})
  }

  getWorkSpaces()
  {
    var data = {
      method: 'GET',
    }
    Api("/workspace/manage/list", data).then(data => {this.setState({workspaces:data})})
  }

  componentWillMount()
  {
    this.getWorkSpaces()
  }
 render() {
   return (
    <div className="content-wrapper  align-items-center auth auth-bg-1 theme-one">
    <ToastContainer store={ToastStore}/>
    <div className="">
      <div className="">
        <div className="row w-100">
          <div className="mx-auto">
            <div className="auto-form-wrapper">
              <form onSubmit={this.onsubmit}>
                <div className="form-group">
                  <label className="label">Workspace Name</label>
                  <div className="input-group">
                    <input name="workspaceName" onChange={this.onchange} type="text" className="form-control" placeholder="Workspace name" />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="mdi mdi-check-circle-outline"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label">Username</label>
                  <div className="input-group">
                    <input name="username" onChange={this.onchange} type="text" className="form-control" placeholder="Username" />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="mdi mdi-check-circle-outline"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label">Confirm Password</label>
                  <div className="input-group">
                    <input type="password" name="password" onChange={this.onchange} className="form-control" placeholder="*********"/>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="mdi mdi-check-circle-outline"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="label">Password</label>
                  <div className="input-group">
                    <input type="password" name="confirm" onChange={this.onchange} className="form-control" placeholder="*********"/>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <i className="mdi mdi-check-circle-outline"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn btn-primary submit-btn btn-block">Register <span className={this.state.loading}></span></button>
                </div>
                <div className="form-group d-flex justify-content-between">
                </div>
                <div className="text-block text-center my-3">
                  <Link to="/" className="text-black text-small">I Have An Account</Link>
                </div>
              </form>
            </div>
            <ul className="auth-footer">
              <li>
                <a href="/">Conditions</a>
              </li>
              <li>
                <a href="/">Help</a>
              </li>
              <li>
                <a href="/">Terms</a>
              </li>
            </ul>
               <p className="footer-text text-center">copyright © {Date().split(" ")[3]} WesEmpire. All rights reserved.</p>
          </div>
        </div>
      </div>
     
     
    </div>
   
  </div>
   
  )}
}

export default Register;
