import React, { Component } from 'react'
import { Api } from '../../helper/Api';

export default class Approve extends Component {
  constructor()
  {
    super()
    this.state = {
      orderS: [],
      message: '',
      alert: ''
    }
    this.getOrders = this.getOrders.bind(this)
  }
  getOrders()
  {
    const data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken")})
    }
    Api("/reorder/request/list", data).then(data => {this.setState({orderS: data})})
  }
  componentWillMount()
  {this.getOrders()}
  render() {
    return (
<div className="main-panel">
        <div className="content-wrapper">
        <h1><span className="fa fa-check"></span> APPROVE RESTOCK ORDERS</h1>

        <hr/>
        <div className={"alert alert-"+this.state.alert}>
          <strong>
            {this.state.message}
          </strong>
        </div>
        <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <td>REQUEST ID</td>
            <td>STOCK ID</td>
            <td>MESSAGE</td>
            <td>ACTION</td>
          </tr>
        </thead>
        <tbody>
          {this.state.orderS.map(data => (
         <tr>
            <td>{data.REQUEST_ID}</td>
          <td>{data.STOCK_ID}</td>
          <td>{data.MESSAGE}</td>
          {data.STATUS === '0' ? <td><button className="btn btn-success" onClick={
            (e, id=data.REQUEST_ID)=>{
              const data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"),
      updateColumn: 'STATUS',
      updateKey: id,
      updateValue: '1'
    })
    }
    Api("/reorder/request/edit", data).then(data=>{
      this.setState({
        alert: data.status,
        message: data.message
      });

      this.getOrders()
    })
            }
          }>APPROVE</button> <button className="btn btn-warning" onClick={
            (e, id=data.REQUEST_ID)=>{
              const data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"),
      updateColumn: 'STATUS',
      updateKey: id,
      updateValue: '2'
    })
    }
    Api("/reorder/request/edit", data).then(data=>{
      this.setState({
        alert: data.alert,
        message: data.message
      });

      this.getOrders()
    })
            }
          }>DISAPROVE(DELETE)</button></td> : data.STATUS === '1' ? <td>Approved</td> : <td>Disapproved</td> }
         </tr>
          ))}
        </tbody>
        </table>
        </div>
        </div>
        
    )
  }
}
