const linsks = [    {
    icon: "tags",
    page: "/stock",
    label: "STOCK MANAGEMENT"
},
{
    icon: "exchange",
    page: "/exchange",
    label: "POINT OF EXCHANGE"
},
{
    icon: "lock",
    page: "/access",
    label: "ACCESS CONTROL"
},
{
    icon: "book",
    page: "/reports",
    label: "REPORTS"
},
{
    icon: "code-fork",
    page:"/depts",
    label:"DEPARTMENT MANAGEMENT"
},
 {
    icon: "wrench",
    page: "/settings",
    label: "WORKSPACE SETTINGS"
},
{
    icon: "truck",
    page: "/shipments",
    label: "SHIPMENT MANAGEMENT"
}, 
{
    icon: "group",
    page: "/clients",
    label: "CLIENT(S) MANAGEMENT"
}, 
{
    icon: "users",
    page: "/suppliers",
    label: "SUPPLIER(S) MANAGEMENT"
},
{
    icon: "refresh",
    page: "/reorder",
    label: "ORDER RESTOCK"
}, 
{
    icon: "check",
    page: "/approve",
    label: "ORDER APPROVAL"
}];

const generateNav =(data)=>
{
  var uri = []
  const apps = [
      {
    stock: linsks[0],
    exchange: linsks[1],
    access: linsks[2],
    reports: linsks[3],
    departments: linsks[4],
    workspace: linsks[5],
    shipment: linsks[6],
    clients: linsks[7],
    supplier: linsks[8],
    restock: linsks[9],
    approve: linsks[10]
    }]
  data.map(data => {uri.push(apps[0][data])})
  return uri
}

export { linsks, generateNav }