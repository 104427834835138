import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import {ToastContainer, ToastStore} from 'react-toasts'
import {Api} from '../../../helper/Api'
export default class AccountType extends Component {
  constructor()
  {
    super()
    this.state = {
      accessArray: [],
      groupName: ""
    }
    this.checkBox = this.checkBox.bind(this);
    this.submit = this.submit.bind(this)
    this.onchange = this.onchange.bind(this)
  }
  checkBox(e)
  {
    var accessArray = this.state.accessArray;
    if(e.target.checked === true)
    {
      var arrays = accessArray;
     arrays.push(e.target.name)
     this.setState({accessArray: arrays})
    } else {
      var arrays = accessArray;
      arrays.splice(accessArray.indexOf(e.target.name))
      this.setState({accessArray: arrays})
    }
    this.setState({accessArray: accessArray})
  }
  submit(e)
  {
    e.preventDefault(); 
    var data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"),groupName:this.state.groupName, accessArray: this.state.accessArray})
    }
    Api('/access/manage/add', data).then(data => {ToastStore.success(data.message)})

    document.getElementById('form').reset();
  }
  onchange(e)
  {
    this.setState({groupName: e.target.value})
  }
  render() {
    return (
      <div>
          <h3><span className="fa fa-"></span> MANAGE ACCOUNT GROUPS</h3>
          <ToastContainer store={ToastStore} />
        <div className="card-body">
            <Link className="btn btn-primary" to="/access/accounts">VIEW ACCOUNTS</Link>  <Link className="btn btn-primary" to="/access">MANAGE ACCOUNTS</Link>
        </div>

        <div className="card">
        <div className="card-header">
            ADD NEW GROUP
        </div>
        <div className="card-body">
            <form onSubmit={this.submit} id="form">
            <div className="form-group col-md-6">
            <label htmlFor="">ENTER GROUP NAME</label>
            <input type="text" className="form-control" name="groupName" onChange={this.onchange} id=""/><br/>
            <h4>CHOOSE ACCESS</h4>
            <div className="form-check form-check-flat ">
            <label className="form-check-label"><input className="form-check-input" type="checkbox" name="stock" onChange={this.checkBox}/><i className="input-helper"></i>STOCK MANAGEMENT</label> </div> 
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="exchange" onChange={this.checkBox}/><i className="input-helper"></i>POINT OF EXCHANGE</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="access" onChange={this.checkBox}/><i className="input-helper"></i>ACCESS CONTROL</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="reports" onChange={this.checkBox}/><i className="input-helper"></i>REPORTS</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="departments" onChange={this.checkBox}/><i className="input-helper"></i>DEPARTMENT MANAGEMENT</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="workspace" onChange={this.checkBox}/><i className="input-helper"></i>WORKSPACE SETTINGS</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="shipment" onChange={this.checkBox}/><i className="input-helper"></i>SHIPMENT MANAGEMENT</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="clients" onChange={this.checkBox}/><i className="input-helper"></i>CLIENTS MANAGEMENT</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="supplier" onChange={this.checkBox}/><i className="input-helper"></i>SUPPLIER MANAGEMENT</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="restock" onChange={this.checkBox}/><i className="input-helper"></i>ORDER RESTOCK</label></div>
            <div className="form-check form-check-flat "><label className="form-check-label"><input className="form-check-input" type="checkbox" name="approve" onChange={this.checkBox}/><i className="input-helper"></i>ORDER APPROVAL</label></div> 
           
            <div></div>
            <br/>
            <button className="btn btn-success">SAVE</button>
            </div>
            </form>
        </div>
        </div>
      </div>
    )
  }
}
