import React, {Component} from 'react'
import { Link } from 'react-router-dom';
import Form from '../../../components/Form';
import {Api} from '../../../helper/Api';
import { ToastStore, ToastContainer } from 'react-toasts';
class Edit extends Component {
  constructor()
  {
    super()
    this.state = {
      loader: "check",
      btnTxt: "SAVE",
      departments: [],
      stockId: "",
      stockName: "",
      returnStatus: "",
      description: "",
      departmentId: "",
      updateColumn: "",
      updateValue: "s",
      stockData: []
    }
    this.getStockInfo = this.getStockInfo.bind(this)
    this.handleType = this.handleType.bind(this)
    this.onchange = this.onchange.bind(this)
    this.onsubmit = this.onsubmit.bind(this)
  }
  componentDidMount()
  {
    this.getStockInfo()
  }
  onchange(e)
  {
    this.setState({updateValue: e.target.value})
    
  }
  getStockInfo()
  {
    const {stockId} = this.props.match.params;
    const data = {
      method: 'POST',
      body: JSON.stringify({token: "Bearer "+localStorage.getItem("appToken"), clientId:this.props.match.params.clientId})
    }
    Api("/clients/manage/find", data).then(data => {this.setState({stockData: data})});
  }
  handleType(selectedOption)
  {
    this.setState({updateColumn: selectedOption.value})
  }
  onsubmit(e)
  {
    e.preventDefault()
    this.setState({loader: "spinner fa-spin", btnTxt:"SAVING.."});
    const body = {
      token: "Bearer "+localStorage.getItem("appToken"), updateColumn: this.state.updateColumn, updateValue: this.state.updateValue, updateKey: this.props.match.params.clientId
    }
    const data =  {method: 'PoST', body:JSON.stringify(body)}
    Api("/clients/manage/edit", data).then(data => {ToastStore.success(data.message); this.setState({loader: "check", btnTxt:"SAVE"})})
  }
  render(){

    const updateOptions = [ {
      value: "CLIENT_NAME",
      label: "CLIENT NAME"
    },{
      value: "CLIENT_CONTACT",
      label: "CLIENT CONTACT"
    }]
  return (
    <div>
      <ToastContainer store={ToastStore} />
      <div className="breadcrumb">
      <Link className="btn   btn-warning" to="/stock"> <span className="fa fa-arrow-left"></span> Back</Link>
      </div>
      <h3>EDITING CLIENT:  {this.props.match.params.clientId}</h3>
      <div className="card">
           <div className="row">
           <div className="col-md-4">
           {this.state.stockData.map(info => (<div className="text-center">
           <h4>CLIENT INFO</h4>
             CLIENT NAME : {info.CLIENT_NAME} <br/>
             CLIENT CONTACT: {info.CLIENT_CONTACT} <br/>
             <br/>
           </div>))}
           </div>
           <div className="col-md-8">
           <Form btnTxt={this.state.btnTxt} loader={this.state.loader} submit={this.onsubmit} form={[{type: "select", label:"SAVE UPDATE FIELD",opts:updateOptions, ph: "UPDATE FIELD", change:this.handleType},{type: "input", name:"description", label:"UPDATE VALUE", ph: "ENTER NEW INFORMATION", change:this.onchange}]}/>
           </div>
           </div>
      </div>
    </div>
  )

}
}

export default Edit;