import React , {Component} from 'react'
import { Link } from 'react-router-dom';

class Tables extends Component {
  constructor()
  {
    super()
    this.state = {
      searchParameter: "",
      currentPage: 1,
      from: 0,
      to: 5

        }
    this.onchange = this.onchange.bind(this);                                                                                                                                                                                               
  }


  componentWillMount()
  {

  }
  onchange(e){
    this.setState({searchParameter: e.target.value})
  }

 
  render() {
            //GLOBAL SPACE SEARCH
            const paginations =(results) =>{
              
              const pages = total%5
              
            }
            let props = this.props
    let results = this.props.data.sort()
                            .filter(
                              (jsonArray) => {
                                return jsonArray[props.search].toLocaleLowerCase()
                                  .indexOf(this.state.searchParameter.toLocaleLowerCase()) !== -1});
                                  const total = results.length
    const state = this.state
    results = results.splice(state.from, state.to)
          
          return (
        <div className="table-resonsive">
            <div className="card">
          
          <div className="card-body table-responsive">
<div className="row">          <input type="text" onChange={this.onchange} placeholder="SEARCH RECORDS..." className="form-control col-md-4" name="" id=""/> 
          <div className="col-md-4"><button onClick={() => {
            var prev = state.currentPage - 1
            prev = prev < 1 ? 1 : prev 
            var prevData = state.from - 5
            prevData = prevData < 0 ? 0 : prevData
            this.setState({currentPage: prev, from: prevData})
          }} className="btn btn-primary">PREVIOUS</button> <button  className="btn btn-primary">{state.currentPage}</button> <button onClick={() => {
            var nextPage = state.currentPage + 1;
            nextPage = nextPage > total ? total : nextPage;
            var nextfrom = state.from + 5
            var nextto =  5
            this.setState({currentPage: nextPage, from: nextfrom })
          }} className="btn btn-primary">NEXT</button></div></div>
         <hr/> <table ref={el => this.el = el} className="container-scroller table table-bordered table-striped" >
            <thead>
              <tr>
               {this.props.columns.map(column => ( <th className="text-center">{column.column}</th> ))}
               <td >ACTIONS</td>
              </tr>
            </thead>
            <tbody>
              {results.map((row, key) => ( <tr>
              {this.props.columns.map(column => ( <td className="">{row[column.dataField]}</td> ))}
              <td className="row"> <Link to={this.props.uri+`/Edit/${row[this.props.keyId]}`} className="btn btn-info btn-rounded"> <span className="fa fa-pencil"></span>  </Link> <Link to={this.props.uri+`/Delete/${row[this.props.keyId]}`} className="btn btn-danger btn-rounded"> <span className="fa fa-trash"></span>  </Link> </td>
              </tr> ))}
            </tbody>
            {paginations(results)}
          </table>
            </div></div>
        </div>
      )
  }
}

export default Tables;

