import React, { Component } from 'react'
import { Route, Switch, Link } from 'react-router-dom';
import Outgoing from './ExchangeApp/Outgoing';
import Incoming from './ExchangeApp/Incoming';
import { Api } from '../../helper/Api';
import { ToastContainer, ToastStore } from 'react-toasts';
import Select from 'react-select';

export default class Exchange extends Component {
  render()
  {
    return(
      <div className="main-panel">
       <div className="content-wrapper">
        <h1><span className="fa fa-exchange"></span> EXCHANGE POINT</h1>

        <hr/>
        <Switch>
          <Route path={this.props.match.path} exact component={Incoming} />
          <Route path={this.props.match.path+"/recieve"} component={Outgoing} />
       </Switch>

         </div>
      </div>
    )
  }
}
