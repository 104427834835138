function setSession(data)
{
    localStorage.setItem("appToken", data);
}

function loggedIn()
{
   const store = localStorage.getItem("appToken");
   if(store !== undefined)
   {
    if(store === null)
    {
        return false
    } else {
        return true
    }
   }else{
       return false
   }
}

const getSessionPayload = () =>
{
    var sessionString = localStorage.getItem("appToken");
    if(sessionString === null)
    {
        return null;
    } else {
        var sessionArry = sessionString.split(".");
    var sessionPayload = sessionArry[1];
    var payload = sessionPayload;
    
    return JSON.parse(atob(payload));
    }
}

export {setSession, loggedIn,getSessionPayload}
